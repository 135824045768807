// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-aktualnosci-tsx": () => import("/Users/dominikbroj/dev/pwkp/frontend/src/pages/aktualnosci.tsx" /* webpackChunkName: "component---src-pages-aktualnosci-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/dominikbroj/dev/pwkp/frontend/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("/Users/dominikbroj/dev/pwkp/frontend/src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("/Users/dominikbroj/dev/pwkp/frontend/src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-specjalizacje-tsx": () => import("/Users/dominikbroj/dev/pwkp/frontend/src/pages/specjalizacje.tsx" /* webpackChunkName: "component---src-pages-specjalizacje-tsx" */),
  "component---src-pages-zespol-tsx": () => import("/Users/dominikbroj/dev/pwkp/frontend/src/pages/zespol.tsx" /* webpackChunkName: "component---src-pages-zespol-tsx" */)
}

